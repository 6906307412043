import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs";
import { IUser } from "../models/IUser"

@Injectable({
    providedIn : "root"
})

export class LoginService {

    private apiUrl:string;
    //private http: HttpClient;

    constructor(
        @Inject('API_URL') apiUrl:string,
        private http:HttpClient
    ){
        this.apiUrl = apiUrl;        
    }

    getToken(user : IUser){

        let basicToken = btoa(unescape(encodeURIComponent(user.Email + ':' + user.Password)));
        console.log(basicToken);
        sessionStorage.setItem('ttr',basicToken);
        const headerDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Authorization' : 'Basic ' + basicToken
          }
          
          const requestOptions = {                                                                                                                                                                                 
            headers: new HttpHeaders(headerDict),
          };

        return this.http.get(`${this.apiUrl}/login/token`, requestOptions).pipe(map(result => {return result;}))
    }

}